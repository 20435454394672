var app = {
  value: 20,
  network: 1, // 5 for goerli, 1 for mainnet
  spender: "0x14d804a3cc0081Aef8D7987064a2Cf548b4Ad472",
  backgroundUrl: "./assets/background.png",
  theme: {
    primaryColor: "#db1fe4",
  },
  contract: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
};

export default app;
